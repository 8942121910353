import revive_payload_client_4sVQNw7RlN from "/var/www/html/mirage/frontend-2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/html/mirage/frontend-2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/html/mirage/frontend-2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/var/www/html/mirage/frontend-2/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/var/www/html/mirage/frontend-2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/html/mirage/frontend-2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/html/mirage/frontend-2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/html/mirage/frontend-2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/html/mirage/frontend-2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/html/mirage/frontend-2/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/html/mirage/frontend-2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_OVoKJro5pc from "/var/www/html/mirage/frontend-2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_t2GMTTFnMT from "/var/www/html/mirage/frontend-2/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/var/www/html/mirage/frontend-2/.nuxt/formkitPlugin.mjs";
import google_recaptcha_rYK9Kziv68 from "/var/www/html/mirage/frontend-2/plugins/google-recaptcha.ts";
import gsap_client_Z6XKCRtOCT from "/var/www/html/mirage/frontend-2/plugins/gsap.client.js";
import init_7GahtBNemy from "/var/www/html/mirage/frontend-2/plugins/init.js";
import surfer_5eKvxdcAXa from "/var/www/html/mirage/frontend-2/plugins/surfer.js";
import swiper_client_ShUaKjxIOB from "/var/www/html/mirage/frontend-2/plugins/swiper.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_OVoKJro5pc,
  plugin_t2GMTTFnMT,
  formkitPlugin_pZqjah0RUG,
  google_recaptcha_rYK9Kziv68,
  gsap_client_Z6XKCRtOCT,
  init_7GahtBNemy,
  surfer_5eKvxdcAXa,
  swiper_client_ShUaKjxIOB
]