<template>
  <div component-name="input-static" class="flex flex-col gap-sm">
    <div class="text-overline/none" v-html="label"></div>
    <div class="truncate text-body/none" v-html="text"></div>
    <slot />
  </div>
</template>

<script setup>
defineProps({
  label: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: false,
    default: "",
  },
});
</script>
