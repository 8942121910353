<template>
    <form-kit 
        component-name="input-zip"
        type="text" 
        name="zip" 
        :label="`${$t.labels.zip} *`"
        :placeholder="$t.labels.type_here"
        validation="required" 
        maxlength="20"
    />
</template>