<template>
  <div
    component-name="divider"
    class="flex flex-col items-stretch @container/divider"
  >
    <hr
      class="border-current"
      v-surfer="{
        setup: {
          destroyOn: 'visible',
        },
        observers: {
          init: {
            class: `${
              revealFrom === 'left' ? 'origin-left' : 'origin-right'
            } scale-x-0`,
          },
          visible: {
            class: `transition-transform duration-700 @[75vw]/divider:duration-1400 tablet:@[50vw]/divider:duration-1400 delay-100 ease-circ-in-out !scale-x-100`,
          },
          offscreenTop: {
            class: '!scale-x-100',
          },
        },
      }"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  revealFrom: {
    type: String,
    required: false,
    validator: function (value) {
      return ["left", "right"].indexOf(value) !== -1;
    },
    default: "left",
  },
});
</script>
