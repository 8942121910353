<template>
  <div component-name="input-company" class="contents">
    <form-kit
      type="text"
      name="company"
      :label="`${$t.labels.company}${isRequired ? ' *' : ''}`"
      :placeholder="$t.labels.type_here"
      :validation="isRequired ? 'required' : undefined"
      maxlength="40"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  isRequired: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>
