<template>
    <form-kit 
        component-name="input-last-name"
        type="text" 
        name="last_name" 
        :label="`${$t.labels.last_name} *`"
        :placeholder="$t.labels.type_here"
        validation="required" 
        maxlength="80"
    />
</template>