<template>
  <form-kit
    component-name="input-form"
    type="form"
    :actions="false"
    :id="id"
    #default="{ value }"
    :value="value"
    @submit="handleSubmit"
    @submit-invalid="handleSubmitInvalid"
  >
    <div :class="innerClass">
      <slot />

      <!-- MANDATORY FIELDS WARNING -->
      <txt-mandatory-fields v-if="useMandatoryFieldsWarning" class="w-full" />
      <!-- end MANDATORY FIELDS WARNING -->

      <!-- PRIVACY FLAG -->
      <input-privacy v-if="usePrivacy" class="w-full" />
      <!-- end PRIVACY FLAG -->

      <!-- COPYRIGHT FLAG -->
      <input-copyright v-if="useCopyright" class="w-full" />
      <!-- end COPYRIGHT FLAG -->

      <!-- MARKETING FLAG -->
      <input-marketing v-if="useMarketing" class="w-full" />
      <!-- end MARKETING FLAG -->

      <div
        v-if="useSubmitBtn || useDiscardEvent"
        class="flex w-full"
        :class="{
          'justify-between items-center gap-2xl':
            useSubmitBtn && useDiscardEvent,
          'justify-start': useSubmitBtn && !useDiscardEvent,
          'justify-end': !useSubmitBtn && useDiscardEvent,
        }"
      >
        <btn-pill
          button-type="submit"
          :icon="submitIcon"
          :title="submitTitle || $t.labels.submit"
        />

        <btn-nav
          v-if="useDiscardEvent"
          :title="$t.labels.discard"
          @click="$emit('discard')"
        />
      </div>
    </div>

    <transition-fade>
      <div v-if="showSuccess" class="absolute inset-0 w-full h-full z-3">
        <span
          class="absolute inset-0 w-full h-full bg-skin-base opacity-80"
        ></span>

        <alert
          class="sticky left-0 w-full top-30 laptop:top-40"
          :dismissible="successDismissible"
          :text="successMessage"
          :button="successBtn || undefined"
          @dismissed="handleSuccessDismissed"
        />
      </div>
    </transition-fade>

    <transition-fade>
      <div v-if="errorMessage" class="absolute inset-0 w-full h-full z-4">
        <span
          class="absolute inset-0 w-full h-full bg-skin-base opacity-80"
        ></span>

        <alert
          class="sticky left-0 w-full top-30 laptop:top-40"
          tone="error"
          :dismissible="true"
          :text="errorMessage"
          @dismissed="handleErrors(null)"
        />
      </div>
    </transition-fade>
  </form-kit>
</template>

<script setup>
import { reset } from "@formkit/core";
import { getValidationMessages } from "@formkit/validation";

const { getRecaptcha } = useGoogleReCaptcha();

const props = defineProps({
  id: {
    type: String,
    required: false,
    default: () => `form-kit-${Math.random().toString(36).substr(2, 9)}`,
  },
  value: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  innerClass: {
    type: String,
    required: false,
    default: "relative top-0 left-0 flex flex-col gap-2xl",
  },
  useMandatoryFieldsWarning: {
    type: Boolean,
    required: false,
  },
  usePrivacy: {
    type: Boolean,
    required: false,
  },
  useCopyright: {
    type: Boolean,
    required: false,
  },
  useMarketing: {
    type: Boolean,
    required: false,
  },
  useSubmitBtn: {
    type: Boolean,
    required: false,
    default: true,
  },
  submitTitle: {
    type: String,
    required: false,
  },
  submitIcon: {
    type: String,
    required: false,
    default: "arrow-long-right",
  },
  submitCallback: {
    type: Function,
    required: false,
  },
  useDiscardEvent: {
    type: Boolean,
    required: false,
  },
  successDismissible: {
    type: Boolean,
    required: false,
    default: false,
  },
  successMessage: {
    type: String,
    required: false,
  },
  successbutton: {
    type: Object,
    required: false,
  },
  successCallback: {
    type: Function,
    required: false,
  },
  autoresetFields: {
    type: Boolean,
    required: false,
    default: true,
  },
  useReCaptcha: {
    type: Boolean,
    required: false,
    default: true,
  },
  handleAppInert: {
    type: Boolean,
    required: false,
    default: true,
  },
  alertDismissedCallback: {
    type: Function,
    required: false,
  },
});

const emit = defineEmits(["update:value", "validation-error"]);

const showSuccess = ref(false);
const errorMessage = ref();
const nuxtApp = useNuxtApp();
const { setAppInert } = useGlobals();

function handleSubmit(fields, actions) {
  if (props.useReCaptcha) {
    getRecaptcha().then((token) => {
      submit({ ...fields, recaptcha: token });
    });
  } else {
    submit(fields, actions);
  }
}

function _setAppInert(value) {
  if (props.handleAppInert) {
    setAppInert(value);
  }
}

function handleSubmitInvalid(fields) {
  emit("validation-error", getValidationMessages(fields));
}

function submit(fields) {
  if (props.submitCallback) {
    _setAppInert(true);
    props.submitCallback(fields).then((response) => {
      const res = response?.data?.value || response;

      console.log("--- INPUT FORM response --->", response, res);

      if (!res || !res.status) {
        handleErrors(nuxtApp.$t.labels.something_went_wrong);
        _setAppInert(false);
      } else if (res.status == "success") {
        if (props.successMessage) {
          showSuccess.value = true;
        }

        if (props.successCallback) {
          props.successCallback(fields);
        }

        handleReset();
      } else {
        handleReset();
        console.log("--- INPUT FORM status --->", res.status);
      }
    });
  }
}

function handleReset() {
  if (props.autoresetFields) {
    reset(props.id);
  }
  _setAppInert(false);
}

function handleSuccessDismissed() {
  showSuccess.value = false;
  if (props.alertDismissedCallback) {
    props.alertDismissedCallback();
  }
}

const handleErrors = (errors) => {
  if (errors) {
    if (typeof errors === "string") {
      errorMessage.value = errors;
    } else if (Array.isArray(errors)) {
      errorMessage.value = errors.join("\n");
    }
  } else {
    errorMessage.value = null;
  }
};

defineExpose({ handleErrors });
</script>
