<template>
  <form-kit
    component-name="input-copyright"
    type="checkbox"
    :label="$t.texts.checkbox_copyright"
    name="copyright"
    validation="accepted"
    validation-visibility="dirty"
    :validation-label="$t.labels.copyright_disclaimer"
  />
</template>
