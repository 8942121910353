<template>
  <form-kit
    component-name="input-message"
    name="description"
    type="textarea"
    :rows="5"
    :label="`${$t.labels.message} *`"
    :placeholder="$t.labels.type_here"
    validation="required"
    maxlength="10000"
  />
</template>
