<template>
  <form-kit v-if="useConfirm" type="group" name="email_group">
    <form-kit
      type="email"
      name="email"
      :label="`${$t.labels.email} *`"
      :placeholder="$t.labels.type_here"
      validation="required|email"
      validation-visibility="dirty"
      maxlength="80"
      autocomplete="on"
    />
    <form-kit
      type="email"
      name="email_confirm"
      :label="`${$t.labels.email_confirm} *`"
      :placeholder="$t.labels.type_here"
      validation="required|confirm"
      validation-visibility="dirty"
      :validation-label="$t.labels.email_confirm"
      maxlength="80"
    />
  </form-kit>

  <form-kit
    v-else
    component-name="input-email"
    type="email"
    name="email"
    :label="`${$t.labels.email} *`"
    :placeholder="$t.labels.type_here"
    validation="required|email"
    maxlength="80"
    autocomplete="on"
  />
</template>

<script setup>
const props = defineProps({
  useConfirm: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>
