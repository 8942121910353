<template>
  <btn
    component-name="chip-label"
    :title="target === 'atelier' ? $t.labels.atelier : $t.labels.resource"
    :action="{
      to: target === 'atelier' ? '/products/atelier' : '/mirage-world/resource',
    }"
    :inert="
      (target === 'atelier' &&
        $route.name === 'country-locale-products-atelier') ||
      (target === 'resource' &&
        $route.name === 'country-locale-mirage-world-resource') ||
      undefined
    "
    :data-skin="
      variant === 'text' || variant === 'text-small' ? 'black' : undefined
    "
    class="max-w-min flex justify-center items-center"
    :class="{
      ' text-skin-base leading-none whitespace-nowrap':
        variant === 'text' || variant === 'text-small',
      'px-md py-sm min-w-[124px]': variant === 'text',
      'px-xs pt-xs pb-2xs min-w-[72px]': variant === 'text-small',
      'bg-black':
        (variant === 'text' || variant === 'text-small') &&
        target === 'atelier',
      'bg-resource':
        (variant === 'text' || variant === 'text-small') &&
        target === 'resource',
    }"
  >
    <!-- TEXT -->
    <span
      v-if="variant === 'text' || variant === 'text-small'"
      class="block leading-none whitespace-nowrap"
      :class="{
        'text-overline-big': variant === 'text',
        'text-overline-small': variant === 'text-small',
        'block -translate-y-px': variant === 'text-small',
      }"
    >
      <span v-if="target === 'atelier'" v-html="$t.labels.atelier"></span>

      <span
        v-else-if="target === 'resource'"
        v-html="$t.labels.resource"
      ></span>
    </span>
    <!-- end TEXT -->

    <!-- LOGO -->
    <svg-atelier v-else-if="variant === 'logo' && target === 'atelier'" />
    <svg-resource v-else-if="variant === 'logo' && target === 'resource'" />
    <!-- end LOGO -->
  </btn>
</template>

<script setup>
const props = defineProps({
  variant: {
    type: String,
    required: true,
    validator: function (value) {
      return ["text", "text-small", "logo"].indexOf(value) !== -1;
    },
  },
  target: {
    type: String,
    required: true,
    validator: function (value) {
      return ["resource", "atelier", "atelier-resource"].indexOf(value) !== -1;
    },
  },
});
</script>
