<template>
  <client-only>
    <TransitionRoot
      component-name="modal-spinner"
      :show="isOpen"
      as="div"
      class="overflow-hidden fixed inset-0 flex flex-col justify-center items-center w-screen h-screen z-50"
      aria-hidden="true"
    >
      <TransitionChild
        enter="transition-opacity duration-300 delay-[2700ms] ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-200 ease-out"
        leave-to="opacity-0"
      >
        <div class="absolute inset-0 w-screen h-screen bg-black/80"></div>
      </TransitionChild>

      <TransitionChild
        enter="transition-reveal duration-300 ease-out delay-[3000ms]"
        enter-from="scale-50 opacity-0"
        enter-to="scale-100 opacity-100"
        leave="transition-reveal duration-300 ease-out"
        leave-to="scale-0 opacity-0"
      >
        <!-- The actual dialog panel -->
        <div
          class="flex flex-col justify-center items-center gap-lg w-full h-screen"
          data-skin="black"
        >
          <spinner :spinning="true" />

          <div
            class="text-center text-skin-body text-skin-base animate-pulse"
            v-html="$t.labels.loading_please_wait"
          ></div>
        </div>
      </TransitionChild>
    </TransitionRoot>
  </client-only>
</template>

<script setup>
import { TransitionRoot, TransitionChild } from "@headlessui/vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>
