<template>
  <div
    component-name="menu-user"
    data-lenis-prevent
    class="flex flex-col gap-4xl bg-skin-base text-skin-base"
  >
    <section class="flex flex-col items-stretch w-full gap-2xl">
      <txt-heading
        size="small"
        :title="$t.labels.signin"
        :text="$t.labels.signin_cta"
        class="w-full"
      />
      <form-signin class="w-full" :onSuccess="onSigninSuccess" />
    </section>

    <section class="flex flex-col items-stretch w-full gap-2xl">
      <txt-heading
        size="small"
        :title="$t.labels.signup_cta_title"
        :text="$t.texts.signup_cta"
      />
      <btn-list
        size="big"
        :items="[
          {
            icon: 'user-pro',
            title: $t.labels.signup_as_professional,
            text: $t.labels.signup_as_professional_cta,
            action: { to: '/signup/professional' },
          },

          {
            icon: 'user',
            title: $t.labels.signup_as_private,
            text: $t.labels.signup_as_private_cta,
            action: { to: '/signup/private' },
          },
        ]"
      />
    </section>
  </div>
</template>
<script setup>
const props = defineProps({
  onSigninSuccess: {
    type: Function,
    required: false,
    default: () => {},
  },
});
</script>
