<template>
  <form-kit
    component-name="input-city"
    type="text"
    name="city"
    validation="required"
    :label="$t.labels.city"
    :placeholder="$t.labels.type_here"
    maxlength="40"
  />
</template>
