<template>
  <div component-name="spinner" class="loader text-skin-base"></div>
</template>

<script setup>
const props = defineProps({
  spinning: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>

<style scoped>
.loader {
  width: 32px;
  height: 32px;
  display: inline-block;
  position: relative;
}

.loader::after,
.loader::before {
  content: "";
  width: 32px;
  height: 32px;
  @apply border-2 border-skin-subtle;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  animation: rotation 2.8s ease-in-out infinite;
}
.loader::after {
  @apply border-current;
  animation-delay: 1.4s;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
