<template>
  <btn
    component-name="btn-color-swatch"
    :title="$attrs.title"
    :action="$attrs.action ? $attrs.action : undefined"
    :disabled="$attrs.disabled ? $attrs.disabled : undefined"
    :pressed="$attrs.pressed ? $attrs.pressed : undefined"
    class="inline-flex justify-center items-center leading-none overflow-hidden rounded-full w-8 h-8 hover:scale-110 transition-transform duration-300 ease-in-out"
    :class="{
      '!scale-110 ring-2 ring-current': $attrs.pressed,
      'ring-transparent': !$attrs.pressed,
    }"
  >
    <media
      v-bind="asset"
      :sizes="{ base: 50 }"
      class="w-6 h-6 overflow-hidden rounded-full"
    />
  </btn>
</template>

<script setup>
const props = defineProps({
  asset: {
    type: Object,
    required: true,
  },
});
</script>
