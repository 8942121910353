<template>
  <header
    component-name="txt-heading"
    class="flex flex-col items-stretch text-skin-base max-w-full"
  >
    <div
      v-if="overline || title || button"
      class="flex flex-col tablet:flex-row tablet:justify-between tablet:items-center gap-xl w-full"
    >
      <div
        class="flex flex-col"
        :class="{
          'gap-2xs': size !== 'small',
          'laptop:gap-0': size !== 'small' && size !== 'product',
          'gap-xs': size === 'small',
        }"
      >
        <wrapper-reveal v-if="overline">
          <div class="text-overline" v-html="overline"></div>
        </wrapper-reveal>

        <wrapper-split-text
          v-if="title"
          :reveal="size !== 'small' ? 'lines' : 'words'"
        >
          <h1
            :class="{
              'text-display-hero': size === 'big',
              'text-heading-1': size === 'regular',
              'text-heading-2': size === 'product',
              'text-heading-4': size === 'small',
            }"
            v-html="title"
          ></h1>
        </wrapper-split-text>
      </div>

      <wrapper-reveal v-if="button" reveal="fade-from-left" class="max-w-min">
        <btn-pill :size="size" :title="button.title" :action="button.action" />
      </wrapper-reveal>
    </div>

    <divider
      v-if="forceDivider || text || atelier || resource"
      class="w-full"
      :class="{
        'mt-xl': title && title === 'product',
        'mt-2xl': (title && title !== 'product') || (!title && button),
        'mt-xs': overline && !title && !button,
        'mb-md': text || atelier || resource,
        'laptop:mb-xl': (text || atelier || resource) && size !== 'small',
      }"
    />

    <div
      class="flex flex-col laptop:flex-row laptop:items-stretch gap-lg justify-start items-start"
      :class="{
        'w-10/12': resource || atelier,
        'w-3/4': !resource && !atelier,
      }"
    >
      <wrapper-reveal
        v-if="atelier || resource"
        reveal="fade-from-bottom"
        class="flex items-stretch justify-start gap-md tablet:gap-lg"
        :class="{
          'laptop:pr-lg laptop:border-r laptop:border-r-current': text,
        }"
      >
        <div
          :class="{
            'flex h-full items-center': atelier && resource,
            contents: !atelier || !resource,
          }"
        >
          <chip-label v-if="atelier" variant="logo" target="atelier" />
          <chip-label v-if="resource" variant="logo" target="resource" />
        </div>
      </wrapper-reveal>

      <wrapper-split-text
        v-if="text && size !== 'small'"
        reveal="lines"
        class="w-full"
        :class="{
          'laptop:pt-3.5': (atelier && resource) || resource,
          'laptop:pt-3': atelier && !resource,
        }"
      >
        <h2
          :class="{
            'text-heading-6': size !== 'small',
            'text-overline': size === 'small' && overline,
            'text-overline-small': size === 'small' && !overline,
          }"
          v-html="text"
        ></h2>
      </wrapper-split-text>

      <wrapper-reveal
        v-else-if="text"
        reveal="fade-from-right"
        class="w-full"
        :class="{
          'laptop:pt-3.5': (atelier && resource) || resource,
          'laptop:pt-3': atelier && !resource,
        }"
      >
        <h2
          :class="{
            'text-heading-6': size !== 'small',
            'text-overline': size === 'small' && overline,
            'text-overline-small': size === 'small' && !overline,
          }"
          v-html="text"
        ></h2>
      </wrapper-reveal>
    </div>
  </header>
</template>

<script setup>
const props = defineProps({
  size: {
    type: String,
    required: false,
    validator: function (value) {
      return ["small", "regular", "big", "product"].indexOf(value) !== -1;
    },
    default: "regular",
  },
  overline: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  button: {
    type: Object,
    required: false,
  },
  forceDivider: {
    type: Boolean,
    required: false,
    default: true,
  },
  atelier: {
    type: String,
    required: false,
  },
  resource: {
    type: Boolean,
    required: false,
  },
  text: {
    type: String,
    required: false,
  },
});
</script>
