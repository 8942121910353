<template>
  <div
    component-name="card-launcher"
    class="@container/card-launcher overflow-hidden"
  >
    <btn
      :title="title"
      :action="action"
      :use-tracking="$attrs.useTracking || undefined"
      class="w-full h-auto overflow-hidden leading-none"
    >
      <div
        class="relative top-0 left-0 grid w-full h-auto grid-cols-1 overflow-hidden leading-none"
      >
        <wrapper-reveal
          reveal="slide-from-up"
          class="w-full row-start-1 row-end-2 col-span-full"
        >
          <media
            class="w-full overflow-hidden transition-transform duration-1000 group-hover/btn:scale-105"
            v-bind="asset"
          />
        </wrapper-reveal>

        <span class="absolute inset-0 overlay-gradient-from-bottom"></span>

        <footer
          class="relative top-0 left-0 flex flex-col justify-end row-start-1 row-end-2 text-white col-span-full gap-2xs p-2xl"
        >
          <wrapper-reveal v-if="overline" reveal="fade-from-right">
            <div class="text-left text-overline/none" v-html="overline"></div>
          </wrapper-reveal>

          <div class="flex w-full max-w-full gap-sm">
            <wrapper-reveal reveal="fade-from-bottom" class="max-w-full">
              <div
                class="text-heading-4/tight @[900px]/card-launcher:text-heading-3/tight @[1100px]/card-launcher:text-heading-2/tight max-w-full"
                v-html="title"
              ></div>
            </wrapper-reveal>

            <wrapper-reveal v-if="titleDetails" reveal="fade-from-down">
              <div class="text-overline" v-html="titleDetails"></div>
            </wrapper-reveal>
          </div>

          <wrapper-reveal v-if="text" reveal="fade-from-right">
            <div class="text-body-big" v-html="text"></div>
          </wrapper-reveal>
        </footer>
      </div>
    </btn>
  </div>
</template>

<script setup>
const props = defineProps({
  overline: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: true,
  },
  titleDetails: {
    type: [String, Number],
    required: false,
  },
  text: {
    type: String,
    required: false,
  },
  asset: {
    type: Object,
    required: true,
  },
  action: {
    type: Object,
    required: false,
  },
  size: {
    type: String,
    required: false,
    validator: function (value) {
      return ["small", "regular"].indexOf(value) !== -1;
    },
    default: "regular",
  },
});
</script>
