<template>
  <form-kit
    component-name="input-hidden"
    type="hidden"
    :name="name"
    :value="value"
    :validation="isRequired ? 'required' : undefined"
  />
</template>

<script setup>
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  isRequired: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>
