<template>
  <component
    component-name="block"
    :data-skin="printSkinAndConfig && config.skin ? config.skin : undefined"
    :data-config="
      printSkinAndConfig &&
      config &&
      !(Object.keys(config).length === 0 && config.constructor === Object)
        ? `config ${JSON.stringify(config)}`
        : undefined
    "
    :is="tag"
    class="relative top-0 left-0 w-full bg-skin-base text-skin-base max-w-screen"
    :class="{
      'flex flex-col gap-4xl': data?.header,
      'before:pointer-events-none before:truncate before:text-body-small before:absolute before:top-0 before:left-0 before:z-50 before:bg-skin-inverse/10 before:p-sm before:text-[blue] before:text-shadow-2xl before:content-[attr(component-name)]':
        printSkinAndConfig,
      'after:pointer-events-none after:truncate after:text-body-tiny after:absolute after:top-14 after:left-0 after:z-50 after:bg-skin-inverse/10 after:p-xs after:text-[red] after:text-shadow-2xl after:content-[attr(data-config)]':
        printSkinAndConfig &&
        !(Object.keys(config).length === 0 && config.constructor === Object),
    }"
  >
    <!-- HEADER -->
    <div
      v-if="data?.header"
      class="component-header relative top-0 left-0 flex flex-col items-stretch w-full max-w-full px-edge"
    >
      <txt-heading
        class="w-full max-w-full"
        :size="data.header.size || 'big'"
        :overline="data.header.overline || undefined"
        :title="data.header.title || undefined"
        :text="data.header.text || undefined"
        :button="data.header.button || undefined"
        :atelier="data.header.atelier || undefined"
        :resource="data.header.resource || undefined"
      />
    </div>
    <!-- end HEADER -->

    <!-- BODY -->
    <div
      class="component-body relative top-0 left-0 w-full max-w-full"
      :class="finalBodyClasses || undefined"
      :data-skin="bodySkin || undefined"
    >
      <slot :loading="loading" :config="config" :data="data?.body" />
    </div>
    <!-- end BODY -->
  </component>
</template>

<script setup>
const props = defineProps({
  tag: {
    type: String,
    required: false,
    default: "section",
  },
  loading: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "lazy"].indexOf(value) !== -1;
    },
    default: "auto",
  },
  config: {
    type: Object,
    required: false,
    default: function () {
      return {};
    },
  },
  data: {
    type: Object,
    required: false,
  },
  px: {
    type: Boolean,
    required: false,
    default: true,
  },
  bodySkin: {
    type: String,
    required: false,
  },
  bodyClass: {
    type: String,
    required: false,
  },
});

const printSkinAndConfig = computed(() => {
  return process.env.NODE_ENV === "development";
});

const finalBodyClasses = computed(() => {
  let finalClasses = props.px ? "px-edge" : null;
  if (props.bodyClass) {
    if (finalClasses) finalClasses += " ";
    finalClasses += props.bodyClass;
  }

  return finalClasses;
});
</script>
