<template>
  <form-kit
    component-name="input-first-name"
    type="text"
    name="first_name"
    :label="`${$t.labels.first_name} *`"
    :placeholder="$t.labels.type_here"
    validation="required"
    maxlength="40"
    autocomplete="on"
  />
</template>
